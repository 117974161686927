/* stylelint-disable selector-class-pattern */
* {
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  text-rendering: optimizelegibility;
}

html {
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-regular;
  font-size: 16px;
  height: 100%;
  margin: 0;
}

.layout-content {
  min-height: 280px;
  padding: 24px;
}
