@keyframes jcn-loader {
  0% {
    transform: rotate(135deg);
  }

  100% {
    transform: rotate(495deg);
  }
}

@keyframes jcn-loader-back {
  0% {
    transform: rotate(135deg);
  }

  100% {
    transform: rotate(-225deg);
  }
}
