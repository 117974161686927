// Colors
$color-black: #191919;
$color-white: #fff;
$color-red: #fe0000;
$color-blue: #02f;

// Typography
$font-title: sans-serif;
$font-subtitle: sans-serif;
$font-light: sans-serif;
$font-regular: sans-serif;
$font-semi: sans-serif;
$font-bold: sans-serif;

// Path Images
$img: '../../images/';
$img-bg: '../../images/backgrounds/';
